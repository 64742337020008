<template>
    <div class="wrap" :class="{ wrap_en: $i18n.locale == 'EN' }">
        <my-header></my-header>
        <div class="section">
            <div class="banner">
                <p>{{ $t('Public.SecurityService.banner.title') }}</p>
            </div>
            <div class="content">
                <div class="big_data">
                    <p class="detail_title">{{ $t('Public.SecurityService.security.title') }}</p>
                </div>
                <div class="security_text">
                    {{ $t('Public.SecurityService.security.text') }}
                </div>
            </div>
        </div>
        <my-footer></my-footer>
    </div>
</template>

<script>
// @ is an alias to /src
import MyHeader from '@/components/MyHeader.vue'
import MyFooter from '@/components/MyFooter.vue'

export default {
    name: 'SecurityService',
    components: {
        MyHeader,
        MyFooter
    },
    data() {
        return {}
    },
    methods: {},
    mounted() {}
}
</script>
<style scoped>
.banner {
    width: 100%;
    height: 4.4rem;
    padding: 1.88rem 0 0 0.77rem;
    background: url(../../public/images/service/security/banner.jpg) no-repeat;
    background-size: 100% 100%;
    background-color: #0c1f3d;
}
.wrap_en .banner {
    /* padding-left: 0;
		text-align: center; */
}
.banner p {
    font-size: 0.46rem;
    line-height: 1.39;
    color: #fff;
}
.content {
    padding: 0.5rem 0.27rem 1.1rem;
}
.security_text {
    line-height: 1.71;
    font-weight: 400;
    margin-top: 0.7rem;
    text-indent: 2em;
    text-align: justify;
}
.detail_title {
    font-size: 0.42rem;
    font-weight: 500;
    text-align: center;
}
</style>
